<template>
  <div style="display:flex;justify-content: space-between;">
      <div style="width: 65%;max-height:500px;overflow-y: scroll;overflow-x: hidden">
        <div>
          <a-space>
            <div>
              <a-input v-model:value="searchForm.content" style="width:120px;" placeholder="内容查询" allowClear />
              <a-select style="width: 120px; margin-left: 8px;" :options="contentType" placeholder="请选择" @change="contentTypeChange" allowClear />
              <a-button type="primary" @click="loadData">搜索</a-button>
            </div>
          </a-space>
        </div>
        <div style="padding-right:5px" v-for="item in records" :key="item.createTime">
          <div class="font-color2">{{item.content}}</div>
          <div class="message font-color"><span>安排人：{{item.operation}}</span><span>安排时间：{{item.createTime}}</span></div>
          <a-divider style="margin:10px 0" />
        </div>
        <div v-show="!promptShow" style="text-align:end"><a @click="reloadMore">加载更多...</a></div>
        <div v-show="promptShow" class="reload-data"><span>已加载全部信息</span></div>
      </div>
      <div style="flex:1;margin-left:10px;display:flex;flex-direction: column;justify-content:flex-end">
        <!-- <a-select placeholder="请选择接受人" allowClear v-model:value="employeeId" style="width:100%">
          <a-select-option v-for="(item,index)  in employeeOptions" :key="index" :value="item.value">{{item.label}}</a-select-option>
        </a-select> -->
        <a-select v-model:value="employeeId" style="width:100%;">
          <a-select-option v-for="(item,index)  in employeeOptions" :key="index" :value="item.value">{{item.label}}</a-select-option>
        </a-select>
        <a-textarea v-model:value="data" placeholder="请输入安排内容" :rows="4" />
        <div style="display:flex;justify-content:end">
          <a-button style="color:#0066CC;border:none;box-shadow:none" @click="send" :loading="sendLoad" type="link">发送安排</a-button>
        </div>
      </div>
<!--    </div>-->
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from 'vue'
import { getVehiclePage, advanceList, vehicleDetail, employeeAll } from '@/api/transport/truck'
import { websocket, employeeList } from '@/api/global'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: String, orderVehicleId: String },
  setup (props) {
    const state = reactive({
      name: '',
      empId: '',
      employeeId: '',
      data: '',
      searchForm: { content: '' },
      advanceLoading: false,
      promptShow: false,
      fild: false,
      sendLoad: false,
      vehDetail: {},
      advanceData: [],
      records: [],
      columns: [
        {
          title: '运输类型',
          dataIndex: 'transportType',
          width: '10%'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          width: '10%'
        },
        {
          title: '运输费用',
          dataIndex: 'transportPrice',
          width: '10%'
        },
        {
          title: '运输状态',
          dataIndex: 'transportStatus',
          width: '10%'
        },
        {
          title: '装车时间',
          dataIndex: 'loadTime',
          width: '10%'
        },
        {
          title: '装车人',
          dataIndex: 'loadSales',
          width: '10%'
        },
        {
          title: '发车时间',
          dataIndex: 'startTime',
          width: '10%'
        },
        {
          title: '发车人',
          dataIndex: 'startSales',
          width: '10%'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '10%'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingSales',
          width: '10%'
        },
        {
          title: '卸车地',
          dataIndex: 'unloadingAddress',
          width: '10%'
        }
      ],
      contentType: [
        {
          label: '操作',
          value: 1
        },
        {
          label: '聊天',
          value: 2
        },
        {
          label: '推送',
          value: 3
        },
        {
          label: '计划',
          value: 4
        }
      ],
      size: 10,
      pagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '10', '20', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      employeeOptions: [],
    })
    const pageChange = page => {
      state.pagination.current = page
      advanceListLoadData()
    }
    // 发送消息
    const send = () => {
      state.sendLoad = true
      const data = { clientTag: 'USER_PC', msgType: 'CHAT', type: 2, orderId: props.orderId, uniqueId: state.employeeId, content: state.data, orderVehicleId: props.orderVehicleId }
      websocket(JSON.stringify(data))
        .then(res => {
          if (res.code === 10000) {
            message.success('发送成功')
            loadData()
            state.data = ""
          }
        }).catch(err => { console.log(err) })
        .finally(() => {
          state.sendLoad = false
        })
    }
    const contentTypeChange = (e, v) => {
      state.searchForm.type = v
    }
    // 日志加载数据
    const loadData = () => {
      getVehiclePage({
        ...state.searchForm,
        current: 1,
        size: state.size,
        orderVehicleId: props.orderVehicleId
      }).then(res => {
        if (res.code === 10000) {
          state.records = res.data.records
          if (state.records.length === res.data.total) {

            state.promptShow = true
          }
          onEmployeeAll()
        }
      }).catch(err => { console.log(err) })
    }
    //大车列表加载数据
    const advanceListLoadData = () => {
      state.advanceLoading = true
      advanceList({
        orderVehicleId: props.orderVehicleId,
        current: state.pagination.current,
        size: state.pagination.pageSize,
      }).then(res => {
        if (res.code === 10000) {
          console.log(res.data)
          state.advanceData = res.data.records
          state.pagination.total = res.data.total
          state.advanceLoading = false
        }
      })
    }
    //基本信息加载数据
    const onObject = () => {
      vehicleDetail(props.orderVehicleId).then(res => {
        if (res.code === 10000) {
          state.vehDetail = res.data
        }
      }).catch(err => { console.log(err) })
    }
    const assignment = e => {
      if (e !== '') state.name = e
    }
    // 加载更多
    const reloadMore = () => {
      state.size += 10
      loadData()
    }
    const onEmployeeAll = () => {
      employeeAll(props.orderVehicleId).then(res => {
        if (res.code === 10000) {
          state.employeeOptions = []
          for (let index = 0; index < res.data.length; index++) {
            state.employeeOptions.push({ value: res.data[index].salesmanId, label: res.data[index].salesman })
          }
          console.log('employeeOptions', state.employeeOptions);
        }
      }).catch(err => { console.log(err) })
    }
    //选择人
    const onEmployeeId = (e, v) => {
      state.employeeId = state.employeeOptions[e].value
      console.log("employeeId", e, v);
    }
    onMounted(() => {
      loadData()
      // onObject()
      // advanceListLoadData()
    })

    watch(
      () => props.orderVehicleId,
      (newValue, oldValue) => {
        state.orderVehicleId = newValue
        state.orderId = props.orderId
        if (newValue !== oldValue) {
          loadData()
        }
      }
    )
    return {
      ...toRefs(state),
      pageChange,
      send,
      onObject,
      assignment,
      reloadMore,
      loadData,
      contentTypeChange,
      advanceListLoadData,
      onEmployeeId,
      onEmployeeAll

    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
