<template>
  <a-modal v-model:visible="modalVisible" title="订单结算信息" :width="1200" :bodyStyle="{height:'70vh',overflow:'auto'}" footer="" @cancel="closeModal">
    <div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <suy-table :reload="loadData" v-model:columns="columns" :scroll="{ x: 1500, y: 300 }">
      <template #search>
        <a-form layout="inline" :model="searchForm" v-for="item in topContent" :key="item">
          <a-form-item label="结算类型" class="w_33">
            {{item.settlementMode.label}}
          </a-form-item>
          <a-form-item label="结算总金额" class="w_33">
            {{item.settableTotalAmt}}
          </a-form-item>
          <a-form-item label="已结算金额" class="w_33">
            {{item.settledAmt}}
          </a-form-item>
          <a-form-item label="可结算金额" class="w_33">
            {{item.settableAmt}}
          </a-form-item>
        </a-form>
      </template>
      <template #toolbarRight>
        <!-- <a-button type="primary" @click="onclick(record,'1')">
          <template #icon>
            <PlusCircleOutlined />
          </template>
          新增
        </a-button> -->
      </template>
      <a-table :columns="columns" :row-key="record => record.orderCode" :data-source="listData" :pagination="false" :loading="loading" @change="handleTableChange">
      </a-table>
    </suy-table>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { getOrderSandPayList } from '@/api/transport/truck'
import SuyTable from '@/components/SuyTable'
export default {
  props: {
    id: String,
    type: Number,
    customerName: String,
    orderSettlement: String
  },
  components: {
    SuyTable
  },
  setup (props) {
    const state = reactive({
      searchForm: {
        name: ''
      },
      modalVisible: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      listData: [],
      topContent: [],
      columns: [
        {
          title: '结算类型',
          dataIndex: 'settlementMode.label',
          key: 'settlementMode.label'
        },
        {
          title: '结算金额',
          dataIndex: 'settableTotalAmt',
          key: 'settableTotalAmt'
        },
        {
          title: '支付金额',
          dataIndex: 'totalAmount',
          key: 'totalAmount'
        },
        {
          title: '支付类型',
          dataIndex: 'businessType.label',
          key: 'businessType'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          key: 'payTime'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '支付流水',
          dataIndex: 'orderCode',
          key: 'orderCode'
        }
      ]
    })
    const loadData = (id) => {
      state.loading = true
      getOrderSandPayList({ orderId: id || props.id}).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.orderSandPayInfoDTOList
          state.topContent = res.data.settlementInfos
          state.loading = false
        }
      })
    }
    const onmodalVisible = (parameter) => {
      if (parameter === true && props.type===undefined) {
        console.log(props.type);
        if(props.id) loadData()
      }
      state.modalVisible = parameter
    }
    const closeModal = () =>{
      state.modalVisible = false
      state.topContent = []
      state.listData = []
    }

    return {
      ...toRefs(state),
      loadData,
      closeModal,
      onmodalVisible
    }
  }
}
</script>

<style lang="less" scoped>
.suspension-button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 16px;
}
.table {
  width: 100%;
  font-size: 14px;
  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }
  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 300px;
  }
  .left-td {
    text-align: left;
  }
  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}
.vehicle-div {
  background: #eee;
}
.contract {
  &-title {
    text-align: center;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-mid {
    border: 1px solid #000;
    &-label {
      border-bottom: 1px solid #000;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    &-value {
      text-indent: 1em;
      border-bottom: 1px solid #000;
      p {
        padding-top: 5px;
      }
    }
    &-text {
      border-bottom: 1px solid #000;
      p {
        text-indent: 2em;
      }
    }
    &-motion {
      color: red;
      p {
        margin: 0;
      }
      &-sign {
        width: 50%;
        margin: 0 0 0 auto;
        display: flex;
        flex-direction: row;
        b {
          flex: 1;
          color: #000;
          span {
            display: inline-block;
            font-weight: normal;
            margin-left: 50px;
          }
        }
      }
    }
  }
  &-head {
    &-label {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }
    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-align: left;
      text-indent: 1em;
    }
  }
  &-content {
    margin-top: 50px;
    font-size: 16px;
    text-indent: 2em;
    p {
      margin-bottom: 15px;
    }
    h1 {
      text-align: center;
    }

    &-sort {
      font-size: 18px;
      font-weight: 600;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      text-indent: 2em;
    }
    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-indent: 1em;
      text-align: left;
      height: 24px;
    }
    &-right {
      text-align: right;
      padding-right: 2em;
    }
  }
  &-footer {
    text-indent: 2em;
    margin-bottom: 8px;

    &-label {
      font-size: 16px;
      font-weight: 600;
    }
    &-value {
      font-size: 16px;
      text-indent: 1em;
    }
  }
  &-seal {
    width: 230px;
    margin-top: -200px;
    margin-left: 60%;
  }
}
.w_33 {
  width: 20%;
}
</style>
