<template>
  <a-drawer v-model:visible="modalVisible" title="验车委托" destroyOnClose width="500px" footer="">
    <div class="infoBox">
      <p>合同号：<span>{{ data.orderId }}</span></p>
      <p>车型：<span>{{ data.brand }}-{{ data.model }}</span></p>
      <p>车牌/车架号：<span>{{ data.vinNo }}</span></p>
      <p>起运地：<span>{{ data.startAddress}}</span></p>
      <p>起运地交车人：<span>{{ data.consignor }}-{{data.consignorMobile}}</span></p>
      <p>目的地：<span>{{ data.endAddress}}</span></p>
      <p>目的地取车人：<span>{{ data.picker }}-{{data.pickerMobile}}</span></p>
      <p>投保额度：<span>{{ data.insuranceFee }}万元</span></p>
      <p>小车运费：<span>{{ data.contractAmt }}元</span></p>
      <p>结算方式：<span>{{ data.orderSettlement?.label }}</span></p>
      <p>是否开票：<span>{{ data.isInvoice?.label }}</span></p>
      <p class=" m-t4">提车方式：<span>{{ data.pickUpType?.label }}&nbsp;&nbsp;{{ data.carNo }}</span></p>
      <p>司机姓名：<span>{{ data.driverName }}</span></p>
      <p>司机电话：<span>{{ data.driverMobile }}</span></p>
      <p>司机身份证：<span>{{ data.driverIdNumber }}</span></p>
      <p>温馨提示：<span> 物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！</span></p>
      <div class="flex ju-end">
        <a-button type="primary" @click="copyData()">一键复制</a-button>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { inspectionCommission } from '@/api/transport/inspection'
import { reactive, toRefs } from 'vue'
import { copyText } from '@/utils/util'
import { message } from 'ant-design-vue'

export default ({
  setup () {
    const state = reactive({
      modalVisible: false,
      data: {
        driverName: '',
        driverMobile: '',
        driverIdNumber: ''
      },
      orderVehicleId: ''
    })

    const copyData = () => {
      let data = state.data
      let str = `合同号：${data.orderId}\n车牌车型：${data.vinNo}-${data.brand}-${data.model}\n起运地：${data.startAddress}\n起运地交车人：${data.consignor}-${data.consignorMobile}\n目的地：${data.endAddress}\n目的地取车人：${data.picker}-${data.pickerMobile}\n投保额度：${data.insuranceFee}万元\n合同金额：${data.contractAmt}元\n结算方式：${data.orderSettlement?.label}\n是否开票：${data.isInvoice?.label}\n提车方式：${data.pickUpType?.label}  ${data.carNo}\n司机姓名：${data.driverName}\n司机电话：${data.driverMobile}\n司机身份证：${data.driverIdNumber}\n温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！`
      copyText(str)
      message.success('文本内容已复制')
    }

    const onVisible = (parameter) => {
      state.data = {
        driverName: '',
        driverMobile: '',
        driverIdNumber: ''
      }
      state.modalVisible = parameter
    }
    const onInspectionCommission = (parameter) => {
      state.orderVehicleId = parameter
      inspectionCommission(state.orderVehicleId).then(res => {
        if (res.code === 10000) {
          if (res.data == null) {
            state.data = {
              driverName: '',
              driverMobile: '',
              driverIdNumber: ''
            }
          } else {
            state.data = res.data
          }
        }
      })
    }
    return {
      ...toRefs(state),
      onVisible,
      copyData,
      onInspectionCommission
    }
  }
})
</script>
<style lang="less" scoped>
.infoBox {
  p {
    color: #6b778c;
    span {
      color: #344563;
    }
  }
}
</style>
