import {
  exportRequest,
  postBodyRequest,
  getRequest
} from '@/utils/axios'

export function add (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/exception/${orderVehicleId}/add`, params)
}
export function batch (params) {
  return postBodyRequest('/trans/order/vehicle/exception/batch', params)
}
export function pageorderVehicleId (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/exception/${orderVehicleId}/page`, params)
}
export function edit (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/exception/${orderVehicleId}/edit`, params)
}
export function dele (exceptionId, params) {
  return postBodyRequest(`/trans/order/vehicle/exception/${exceptionId}/delete`, params)
}

export function page (params) {
  return postBodyRequest('/trans/order/vehicle/exception/page', params)
}
export function pageOrderId (orderId,params) {
  return postBodyRequest(`/trans/order/vehicle/exception/order/${orderId}/page`, params)
}
export function pageOrderVehicleId (orderVehicleId,params) {
  return postBodyRequest(`/trans/order/vehicle/exception/${orderVehicleId}/page`, params)
}
export function exportXls (params, fileName) {
  return exportRequest('/trans/order/vehicle/exception/export', params, fileName)
}

export function orderRemark (orderId) {
  return getRequest(`/trans/order/orderRemark/orderRemark/${orderId}`)
}

export function orderMessage (orderId) {
  return getRequest(`/trans/order/orderRemark/message/${orderId}`)
}

export function replyMessage (params) {
  return postBodyRequest('/trans/order/orderRemark/message', params)
}
