<template>
  <a-drawer v-model:visible="modalVisible" title="运输详情" placement="right" width="86%" destroyOnClose>
    <a-table :row-key="record => record.totvId" :dataSource="listVehicleTransData" :pagination="false">
      <a-table-column title="序号">
        <template #default="{ index }">{{index+1}}</template>
      </a-table-column>
      <a-table-column title="运输类型" data-index="transportType.label" />
      <a-table-column title="运输车牌" data-index="carNo" />
      <a-table-column title="运输费用" data-index="transportFee" />
      <a-table-column title="运输状态" data-index="truckStatus.label" />
      <a-table-column title="线路" data-index="lineName" />
      <a-table-column title="装车时间" data-index="loadingTime" />
      <a-table-column title="装车人" data-index="loadingOperator" />
      <a-table-column title="发车时间" data-index="departureTime" />
      <a-table-column title="发车人" data-index="departure" />
      <a-table-column title="卸车地" data-index="" />
      <a-table-column title="卸车时间" data-index="unloadingTime" />
      <a-table-column title="卸车人" data-index="unloadingOperator" />
    </a-table>
     <div class="m-t1 m-b1">任务详情</div>
     <TransportTasksModal type="carTable" :dispatchDetail="dispatchDetail"></TransportTasksModal>
  </a-drawer>
</template>

<script>
import { vehicleTransDetail, vehicleTasks } from '@/api/transport/truck'
import { reactive, toRefs, ref } from 'vue'
import TransportTasksModal from '@/views/components/transport/smallVehicleOperate/transportTasksModal.vue'


export default ({
  components: {
    TransportTasksModal
  },
  setup () {
    const dispatchDetail = ref({
      tasks: []
    })
    const state = reactive({
      loading: false,
      modalVisible: false,
      listVehicleTransData: [],
      pagination: {},
      listData: [],
      orderVehicleId: ''
    })

    const onvisible = (parameter) => {
      state.modalVisible = parameter
    }
    const onorderVehicleId = (parameter) => {

      state.loading = true
      state.orderVehicleId = parameter
      vehicleTransDetail(state.orderVehicleId, {
      }).then(res => {
        if (res.code === 10000) {
          state.listVehicleTransData = res.data
          state.loading = false
        }
      })
      reqVehicleTasks()
    }
    const reqVehicleTasks = () => {
      vehicleTasks(state.orderVehicleId,{}).then( res => {
        if(res.code !== 10000) return
        dispatchDetail.value.tasks = res.data
      })
    }
    return {
      ...toRefs(state),
      onvisible,
      onorderVehicleId,
      dispatchDetail,
    }
  }
})
</script>
