<template>
  <a-modal v-model:visible="windowShow" title="异常列表" footer='' width="60%">
    <a-table :columns="abnormalColumns" :data-source="abnormalDataLeft" :pagination="abnormalPagination"
      :row-key="record => record.vinNo" :loading="abnormalLoading">
      <template #fileUrl="{ record }">
        <a-image width="100px" height="50px" v-if="record.fileUrl" :src="`${record.fileRealUrl}`" />
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { pageOrderVehicleId, pageOrderId } from '@/api/transport/orderException'

export default {
  setup () {
    const state = reactive({
      windowShow: false,
      abnormalLoading: false,
      abnormalDataLeft: [],
      abnormalColumns: [
        {
          title: '异常类型',
          dataIndex: 'exceptionType.label',
          key: 'exceptionType.label',
          ellipsis: true
        },
        {
          title: '异常原因',
          dataIndex: 'exceptionSubType.label',
          key: 'exceptionSubType.label',
          ellipsis: true
        },
        {
          title: '异常说明',
          dataIndex: 'exceptionExplain',
          key: 'exceptionExplain'
        },
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '上报时间',
          dataIndex: 'explainTime',
          key: 'explainTime'
        },
        {
          title: '图片',
          width: 200,
          dataIndex: 'fileUrl',
          key: 'fileUrl',
          slots: {
            customRender: 'fileUrl'
          }
        },
        {
          title: '上报人名',
          dataIndex: 'explainer',
          key: 'exceptionExplain'
        },
        {
          title: '上报机构',
          dataIndex: 'orgName',
          key: 'orgName'
        }
      ],
      abnormalPagination: {
        current: 1,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const openWindow = (boolean) => {
      state.windowShow = boolean
    }
    const loadData = (orderId, orderVehicleId) => {
      state.abnormalLoading = true
      if (orderId) {
        pageOrderId(orderId, {
          orderId: orderId,
          current: state.abnormalPagination.current,
          size: state.abnormalPagination.pageSize
        }).then(res => {
          if (res.code === 10000) {
            state.abnormalDataLeft = res.data.records
            state.abnormalPagination.total = res.data.total
          }
        }).finally(()=>{state.abnormalLoading=false})
      } else {
        pageOrderVehicleId(orderVehicleId,{
          orderVehicleId: orderVehicleId,
          current: state.abnormalPagination.current,
          size: state.abnormalPagination.pageSize
        }).then(res => {
          if (res.code === 10000) {
            state.abnormalDataLeft = res.data.records
            state.abnormalPagination.total = res.data.total
          }
        }).finally(()=>{state.abnormalLoading=false})
      }
    }

    return {
      ...toRefs(state),
      loadData,
      openWindow
    }
  }
}
</script>

<style lang="less" scoped></style>