<template>
  <a-modal v-model:visible="windowShow" width="60%" title="发运方案" footer="">
    <div>
      <!-- 原始方案 -->
      <div class="content-title">原始方案</div>
      <div class="content-data">
        <div style="text-align:center" v-show="originalPlanList.length <= 0">暂无原始方案</div>
        <div class="content-div" v-show="originalPlanList.length > 0">
          <div class="plant-data" v-for="(item, index) in originalPlanList" :key="index">
            <div class="city-data" v-show="item.startCityName">{{ item.startCityName }}</div>
            <div class="detail-data-plant">
              <div class="top-data">{{ '￥' + item.cost }}<span
                  v-show="item.prescription && item.prescription !== null">&nbsp;{{
                    item.prescription + '天' }}</span></div>
              <div style="white-space:normal;">{{ item.carrierName || '\u3000' }}</div>
            </div>
            <div class="city-data" v-show="item.endCityName">{{ item.endCityName }}</div>
          </div>
        </div>
      </div>
      <!-- 实际方案 -->
      <div class="content-title">实际方案</div>
      <div class="content-data">
        <div style="text-align:center" v-show="practicalList.length <= 0">暂无实际方案</div>
        <div class="content-div" style="color:#0C8226" v-show="practicalList.length > 0">
          <div class="plant-data" v-for="(item, index) in practicalList" :key="index">
            <div class="city-data" v-show="item.startAddress">{{ item.startAddress }}</div>
            <div class="detail-data-plant">
              <div class="top-data" style="border-color:#0C8226">{{ '￥' + item.freightCost }}<span
                  v-show="item.usedTime && item.usedTime !== null">&nbsp;{{ item.usedTime }}</span></div>
              <div style="white-space:normal;">{{ item.carrierName || '\u3000' }}</div>
            </div>
            <div class="city-data" v-show="item.endAddress">{{ item.endAddress }}</div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { getReclineData, getActualRouteLine } from '@/api/transport/transport'

export default {
  props: {
    orderId: String,
    orderVehicleId: String
  },
  setup (props) {
    const state = reactive({
      windowShow: false,
      originalPlanList: [],
      practicalList: []
    })
    const loadData = (orderId,orderVehicleId) => {
      state.windowShow = true
      if(orderId) state.orderId = orderId
      if(orderVehicleId) state.orderVehicleId = orderVehicleId
      getTaskLineData()
      getActualRouteLineData()
    }
    // 原始方案数据获取
    const getTaskLineData = () => {
      state.originalPlanList = []
      getReclineData({ orderId: props.orderId || state.orderId })
        .then(res => {
          if (res.code === 10000) {
            state.originalPlanList = res.data
            if (state.originalPlanList.length > 0) {
              state.originalPlanList.forEach((v, i) => {
                if (i + 1 < state.originalPlanList.length) {
                  if (v.endCityName == state.originalPlanList[i + 1].startCityName) v.endCityName = null
                }
              })
            }
          }
        })
    }
    // 实际方案获取
    const getActualRouteLineData = () => {
      state.practicalList = []
      getActualRouteLine({ orderVehicleId: props.orderVehicleId || state.orderVehicleId }).then(res => {
        if (res.code === 10000) {
          state.practicalList = res.data
          state.practicalList.forEach((v, i) => {
            if (i + 1 < state.practicalList.length) {
              if (v.endAddress == state.practicalList[i + 1].startAddress) v.endAddress = null
            }
          })
        }
      })
    }
    return {
      ...toRefs(state),
      loadData,
      getTaskLineData,
      getActualRouteLineData
    }
  }
}
</script>

<style lang="less" scoped>
.content-title {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  color: #344563;
  font-weight: 700;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
}

.content-data {
  padding: 5px;
  padding-bottom: 0;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  flex-wrap: nowrap;
  background: rgba(242, 242, 242, .3);

  .plant-data {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .city-data {
      flex-wrap: nowrap;
      white-space: nowrap;
      margin: 0 5px;
    }

    .detail-data-plant {
      min-width: 200px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
}

.content-div {
  max-width: 200%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
}

.top-data {
  text-align: center;
  border-bottom: 1px solid #ccc;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}
</style>